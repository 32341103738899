export enum BELT_EVENT_TYPES {
  BELT_NEW_BELT_DETECTED = 601,
  BELT_INPUT_FAULT = 602,
  BELT_NO_ENV_MESSAGE = 603,
  BELT_FEW_ENV_MESSAGE = 604,
  BELT_MANY_ENV_MESSAGE = 605,
  BELT_STOPPED = 606,
  BELT_CONFIGURATION_FAILED = 607,
  BELT_OUTPUT_STATUS_CHANGED = 610,
  BELT_OUTPUT_STATUS_ON = 611,
  BELT_CONFIGURATION_ACK = 632
}

export const BELT_COLORS: {
  [value in BELT_EVENT_TYPES]: string | null;
} = {
  [BELT_EVENT_TYPES.BELT_NEW_BELT_DETECTED]: 'belt-color.belt_event_color_601',
  [BELT_EVENT_TYPES.BELT_INPUT_FAULT]: 'belt-color.belt_event_color_602',
  [BELT_EVENT_TYPES.BELT_NO_ENV_MESSAGE]: 'belt-color.belt_event_color_603',
  [BELT_EVENT_TYPES.BELT_FEW_ENV_MESSAGE]:
    'belt-color.belt_event_color_604',
  [BELT_EVENT_TYPES.BELT_MANY_ENV_MESSAGE]:
    'belt-color.belt_event_color_605',
  [BELT_EVENT_TYPES.BELT_STOPPED]: 'belt-color.belt_event_color_606',
  [BELT_EVENT_TYPES.BELT_CONFIGURATION_FAILED]:
    'belt-color.belt_event_color_607',
  [BELT_EVENT_TYPES.BELT_OUTPUT_STATUS_CHANGED]:
    'belt-color.belt_event_color_608',
  [BELT_EVENT_TYPES.BELT_OUTPUT_STATUS_ON]: 'belt-color.belt_event_color_611',
  [BELT_EVENT_TYPES.BELT_CONFIGURATION_ACK]:
    'belt-color.belt_event_color_632',
};

const getBinaryRegex = /^[01]+$/;

const getBitsFromBinaryString = (binaryStr: string, power: string) => {
    const index = Math.log2(parseInt(power));

    if (typeof binaryStr === 'string' && getBinaryRegex.test(binaryStr)) {
        if (Number.isInteger(index) && index >= 0 && index < binaryStr.length) {
            return binaryStr[binaryStr.length - 1 - index].toString();
        } else {
            return 'undefined';
        }
    } else {
        return 'undefined';
    }
};

export const getBeltNodeStatus = (name: string, data: object | undefined, outputBits: string | null | undefined) => {
  if (data && outputBits) {
    const statusByName = Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
    return getBitsFromBinaryString(outputBits, statusByName[name]);
  } else {
    return 'undefined';
  }
}


export const getBeltInputStatus = (data: object | undefined, inputBits: string | null | undefined) => {
  if (data && inputBits) {
    let inputStatusTxt = "";
    const statusByName = Object.fromEntries(Object.entries(data).map(([key, value]) => [value, key]));
    Object.values(data).map((it) => {
      if (getBitsFromBinaryString(inputBits, statusByName[it]) === '1') {
        inputStatusTxt = `${inputStatusTxt}, ${it}`;
      }
      inputStatusTxt = inputStatusTxt.replace(/^,/, '');
    })
    return inputStatusTxt;
  } else {
    return '';
  }
}
