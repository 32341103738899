import update from 'immutability-helper';
import {isEmpty} from 'lodash';
import {useMemo} from 'react';

import {DashboardPanelData} from '../../../interfaces/Dashboard';
import {
  BeltHistory,
  BeltHistoryReportData,
  getBeltHistoryReportData,
} from './BeltHistoryReport';

interface Props {
  value?: DashboardPanelData;
  onUpdate?: (value?: DashboardPanelData) => void;
}

export interface BeltTrackingReportData {
  viewType: 'belts';
  title: string;
  belt: BeltHistoryReportData;
}

const getBeltTrackingReports = (): BeltTrackingReportData => ({
  viewType: 'belts',
  title: 'Belt History Report',
  belt: getBeltHistoryReportData(),
});

export const BeltHistoryReport = ({value, onUpdate}: Props) => {
  const config = useMemo(
    () =>
      !isEmpty(value)
        ? (value as BeltTrackingReportData)
        : getBeltTrackingReports(),
    [value]
  );

  return (
    <BeltHistory
      value={config.belt}
      onUpdate={(v) => {
        onUpdate?.(
          update(value ?? getBeltTrackingReports(), {
            belt: {
              $set: v ?? getBeltHistoryReportData(),
            },
          })
        );
      }}
    />
  );
};
