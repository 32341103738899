import {Box} from '@mui/material';
import Image from 'mui-image';

import wifidIcon from './wifid.svg'
export const WifiDIcon = () => {
    return (
      <Box
        sx={{
          '& img': {transition: 'none !important', animation: 'none !important'},
          transition: 'none !important'
        }}
      >
        <Image
          src={wifidIcon}
          alt="wi-fi-c"
          width={20}
          height={20}
        />
      </Box>
    );
};

export default WifiDIcon;
