export enum Role {
  superUser = 1,
  strataManager = 2,
  clientManager = 3,
  clientViewer = 5,
}

export enum RoleNames {
  superUser = 'Super User',
  strataManager = 'Strata Manager',
  clientManager = 'Client Manager',
  clientViewer = 'Client Viewer',
}

const permissions = [
  {
    id: 'get::/debug',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/dashboard',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/dashboard',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/dashboard/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/dashboard/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'delete::/dashboard/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/dashboard/:id(\\d+)/favourite',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'delete::/dashboard/:id(\\d+)/favourite',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/dashboard/:id(\\d+)/primary',
    roles: [1, 2],
    configurable: false,
  },
  {
    id: 'delete::/dashboard/:id(\\d+)/primary',
    roles: [1, 2],
    configurable: false,
  },
  {
    id: 'get::/dashboard-panel-templates',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/dashboard-panel-templates',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/dashboard-panel-templates/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/dashboard-panel-templates/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/dashboard-panel-templates/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/query',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/report/machine-interaction-gantt',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-overview-bar',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-overview-line',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-overview-export',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-details-bar',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-details-line',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-details-export',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-history-export',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-status',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-interaction-chart',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/machine-interaction-grid',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/human-overview',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/human-overview-export',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/human-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/human-history-export',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/checkout-overview-grid',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/checkout-overview-chart',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/checkout-details-grid',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/checkout-details-chart',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/report/heat-map',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/machine-input',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/machine-input',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/machine-input/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/machine-input/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/machine-input/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/machine-type',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/machine-type',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/machine-type/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/machine-type/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/machine-type/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/map',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/map',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/map/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'delete::/map/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/company/current',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/company',
    roles: [1],
    configurable: false,
  },
  {
    id: 'get::/company/:id',
    roles: [1],
    configurable: false,
  },
  {
    id: 'post::/company',
    roles: [1],
    configurable: false,
  },
  {
    id: 'patch::/company/current',
    roles: [1, 2],
    configurable: false,
  },
  {
    id: 'patch::/company/:id',
    roles: [1],
    configurable: false,
  },
  {
    id: 'delete::/company/:id',
    roles: [1],
    configurable: false,
  },
  {
    id: 'delete::/company/:id/data',
    roles: [1],
    configurable: false,
  },
  {
    id: 'delete::/company/:id/asset',
    roles: [1],
    configurable: false,
  },
  {
    id: 'get::/subscription',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/subscription',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/subscription/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/subscription/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/subscription/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/subscription-report',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/subscription-report',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/subscription-report/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/subscription-report/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/subscription-report/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-machine',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/asset-machine',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/asset-machine/remove-events',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/asset-machine/remove-machines',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-machine/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/asset-machine/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/asset-machine/:id/update_network_id',
    roles: [1, 2, 3],
    configurable: true,
    label: 'Update Asset Network ID',
  },
  {
    id: 'delete::/asset-machine/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/asset-human',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/asset-human/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/asset-human/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human-type',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/asset-human-type',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human-type/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/asset-human-type/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/asset-human-type/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human-group',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/asset-human-group-grid',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/asset-human-group-types',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/asset-human-group',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset-human-group/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/asset-human-group/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/asset-human-group/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/report/machine-summary',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/configuration',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/configuration',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/configuration',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/user-configuration',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/user-configuration',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/user-configuration',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/user/:user_id/permissions',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/user/:user_id/permissions',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/logo',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/logo',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/asset',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/layer',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/layer',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/layer/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/layer/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/layer/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/zone',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/zone-by-group-id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/zone',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/zone/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/zone/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/zone/:id',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/site',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/site',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/site/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/site/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/site/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/commtrac-node',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node-grid',
    roles: [1, 2, 3, 5],
    configurable: true,
    label: 'Employees/Assets Tab Access',
  },
  {
    id: 'post::/commtrac-node',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/commtrac-node/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node/remove-events',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node/remove-employees',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node/:id/history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/activate',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/deactivate',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/miner/broadcast_report_frequency',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/safeye-nano-node',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/safeye-nano-node',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/safeye-nano-node/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/safeye-nano-node/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/safeye-nano-node/:id(\\d+)/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/safeye-nano-node/:id',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/belt',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/belt/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/:id(\\d+)/belt-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/:id(\\d+)/belt-location-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/belt',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/belt',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/belt/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/belt/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/belt/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/ams',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/emodule',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/emodule/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/:id(\\d+)/short-term-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/:id(\\d+)/long-term-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/ams/:id(\\d+)/location-history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/ams',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/ams/emodule',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/ams/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/ams/:id(\\d+)/stand_down',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/ams/:id(\\d+)/network_id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/ams/emodule/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/ams/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/ams/emodule/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },

  {
    id: 'get::/auth/logout',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/user/me',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/user/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/user',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/user',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/user/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'delete::/user/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node-miner/force-check-in',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node-miner/force-check-out',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/communication-node/remove-events',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/communication-node/remove-nodes',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/m-processor/get-logs',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/m-processor/start',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/m-processor/stop',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/m-cron/status',
    roles: [1, 2],
    configurable: false,
  },
  {
    id: 'get::/commtrac-broadcast-message',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-broadcast-message/:id/clear',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/commtrac-broadcast-message',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/event',
    roles: [1, 2, 3, 5],
    configurable: true,
    label: 'Events Grid',
  },
  {
    id: 'get::/event/summary',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/event/acknowledge',
    roles: [1, 2, 3, 5],
    configurable: true,
    label: 'Acknowledge Events',
  },
  {
    id: 'patch::/event/acknowledge-all',
    roles: [1, 2, 3, 5],
    configurable: true,
    label: 'Acknowledge All Events',
  },
  {
    id: 'post::/purge',
    roles: [2],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/miner/force_check_in',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/miner/force_check_out',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/miner/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/miner/move_to_asset',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/miner/report_frequency',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/asset-human/:id/update_network_id',
    roles: [1, 2, 3],
    configurable: true,
    label: 'Update Employee Network ID',
  },
  {
    id: 'patch::/commtrac-node/:id/asset/force_check_in',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/asset/force_check_out',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id/asset/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node-message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node-message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/shift',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/shift',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/shift/:id',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/shift/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/shift/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node/grid',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/communication-node/network-general-grid',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/communication-node/network-routing-grid',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/communication-node/network-timing-grid',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/communication-node/event-grid',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/communication-node/events',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/node',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/node/activate',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/deactivate',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/node/communication-node',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/node/communication-node',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/node/:id/communication-node',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node/reset',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node/update_networkd_id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node/report_frequency',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/communication-node/make_secondary',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/node/communication-node/broadcast_report_frequency',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/node/wifi-point',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/node/:id/wifi-point',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/node/:id/wifi-point/events',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/node/:id/wifi-point/long-events',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/wifi-point',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/wifi-point/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/node/:id/wifi-point/update_networkd_id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/backhauler',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/backhauler/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/backhauler',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/backhauler/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backhauler/:id(\\d+)/start',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backhauler/:id(\\d+)/stop',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/system/status',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/commtrac-node/:id/message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/commtrac-node/:id/message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/commtrac-node/:id/message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/group-message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/group-message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/group-message',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/proximity/import',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'post::/proximity/import',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/proximity/import/:id',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/alarm-module',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/alarm-module',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/alarm-module/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/alarm-module/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/alarm-module/:id(\\d+)/acknowledge',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/alarm-module/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/alarm-module-group',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'post::/alarm-module-group',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/alarm-module-group/:id(\\d+)',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'patch::/alarm-module-group/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'delete::/alarm-module-group/:id(\\d+)',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/alarm-module/alarm/enable',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/alarm-module/alarm/disable',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/alarm-module/:id(\\d+)/history',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/alarm-module/:id(\\d+)/log',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
  {
    id: 'get::/user/:id(\\d+)/event-subscription',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/user/:id(\\d+)/event-subscription',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backend/restart',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backend/frontend/restart',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backend/mqtt/start',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/backend/mqtt/stop',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id(\\d+)/miner/reassign',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'patch::/commtrac-node/:id(\\d+)/asset/reassign',
    roles: [1, 2, 3],
    configurable: false,
  },
  {
    id: 'get::/hazard-ai/detection-heat-map',
    roles: [1, 2, 3, 5],
    configurable: false,
  },
] as const;

export type Permission = (typeof permissions)[number]['id'];

export type Rule = 'canConfigurationProducts';

export const rules: {
  [k in Rule]: number[];
} = {
  canConfigurationProducts: [1, 2],
};
