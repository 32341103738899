export default {
  HazardAI: 'HazardAI',
  HazardAIDectionLog: 'HazardAI Detection Log',
  HazardAIMachineUtilizationChart: 'HazardAI Machine Utilization Chart',
  HazardAIMachineSpeedChart: 'HazardAI Machine Speed Chart',
  HazardAISummaryChart: 'HazardAI Summary Chart',
  HazardHeatMapReports: 'Hazard Heatmap',
  Map: 'Map',
  AlarmGrid: 'Alarm Grid',
  AlarmHistoryReports: 'Alarm History Reports',
  AlarmLogReports: 'Alarm Log Reports',
  CnWifiNodes: 'Communication/Wifi Nodes',
  EmployeeAssetsGrid: 'Employees/Assets',
  MachineDetailsReport: 'Machine Details Report',
  MachineOverviewReport: 'Machine Overview Report',
  MachineHistoryReport: 'Machine History Report',
  MachineStatusReport: 'Machine Status Report',
  EmployeeHistoryReport: 'Employee History Report',
  EmployeeOverviewReport: 'Employee Overview Report',
  MachineInteractionReport: 'Machine Interaction Report',
  MachineSummaryReport: 'Machine Summary Report',
  CheckoutStationOverviewReport: 'Checkout Station Overview Report',
  CheckoutStationDetailsReport: 'Checkout Station Details Report',
  InteractionsPerMachine: 'Interactions Per Machine',
  TopEmployeeInteractions: 'Top Employee Interactions',
  ConnectView: 'Connect View',
  MinerMap: 'Miner Map',
  InteractionsLastDays: 'Trend of Interactions',
  MCronLogs: 'M-Cron',
  MProcessorLogs: 'M-Processor',
  SystemServicesTable: 'System Services',
  CommtracNodeTrackingReports: 'Employee/Asset Tracking Reports',
  NodeTrackingReports: 'Node Tracking Reports',
  NetworkDiagnostics: 'Network Diagnostics',
  BackHaulers: 'BackHaulers',
  Nginx: 'Nginx',
  Backend: 'Backend',
  Mqtt: 'MQTT',
  GasMonitoringReport: 'Gas Monitoring Report',
  AmsEmoduleInstallationHistory: 'Installation History Report',
  AmsEmoduleSensorHistory: 'Sensor History Report',
  AMSEModuleCalibration: 'eModule Calibration History Report',
  BeltHistoryReport: 'Belt History Report',
  BeltLocationHistoryReport: 'Belt Location History Report',
};
