import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
// import {
//   OpenedEntityMode,
//   OpenedEntityType,
// } from '../../../utils/connect-view-panel';
//import AccessControl from '../../common/AccessControl';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
//import {AutoRefreshSettingsSelect} from '../../common/AutoRefreshSettingsSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import {Map, /*MapLatLangCoordinates,*/ MapLayerId} from '../../common/Map';
import {ResizableColumns} from '../../common/ResizableColumns';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
//import {ZoneSelect} from '../../selectors/ZoneSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value: BeltLocationHistoryReportData;
  onUpdate?: (value?: BeltLocationHistoryReportData) => void;
}

const DEFAULT_SHOWN_FIELDS = ['pos_lat', 'pos_lon', 'date'];

interface BeltLocationHistoryReportDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll?: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    status?: string;
  };
  commtrac_external_id?: number;
}

export interface BeltLocationHistoryReportData {
  activeId?: number | undefined;
  activeCommtracId?: number | undefined;
  mapLayers?: MapLayerId[] | undefined;
  mapLevel: number | null;
  openedItems: BeltLocationHistoryReportDataTab[] | [];
}

export const getBeltLocationHistoryReportData =
  (): BeltLocationHistoryReportData => ({
    activeId: undefined,
    activeCommtracId: undefined,
    openedItems: [],
    mapLayers: ['street', 'mine', 'belts'],
    mapLevel: null,
  });

export const getBeltLocationHistoryReportDataTab = (
  id: number,
  commtrac_external_id?: number
): BeltLocationHistoryReportDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
  commtrac_external_id,
});

export const BeltLocationHistory = ({value, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config: BeltLocationHistoryReportData = useMemo(() => {
    const v = value ?? getBeltLocationHistoryReportData();
    return {
      ...v,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex]
          ? config.openedItems[openedItemIndex]
          : getBeltLocationHistoryReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const belts = useAppSelector(({assets}) => assets.belt_nodes);
  const tabNames = useMemo(
    () =>
      config.openedItems.map((o) => {
        const belt = belts.find((i: any) => i.id === o.id);
        return belt?.name || `Belt #${belt?.id}`;
      }),
    [config.openedItems, belts]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<any>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            status: openedItem.params?.status ?? 'all',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: any) => {
      try {
        setFetchedInProgress(true);
        try {
          const resp = await API.get(
            `${apiBaseUrl}/belt/${openedItem?.commtrac_external_id}/location`,
            {params}
          );
          setFetchedData(resp.data);
        } catch (error: any) {
          const messages = getMessagesFromApiError(error);
          setFetchedErrors(messages);
        }
        setFetchedInProgress(false);
      } catch (error) {
        console.log(error);
      }
    },
    [params, openedItem]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /************/
  /* map data */
  /************/
  // const mapData = useMemo(() => {
  //   if (fetchedData?.items && config.selectedIds?.length) {
  //     return fetchedData?.items.filter((i) =>
  //       config.selectedIds?.includes(i.id)
  //     );
  //   }
  //   return [];
  // }, [fetchedData, config.selectedIds, selectedItems]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid  */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<any>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => {
        return (
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        );
      },
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(`${row.id}`)}
          onChange={() => toggleSelectItem(`${row.id}`)}
        />
      ),
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.date,
      renderCell: ({row}) => row.date,
    },
    {
      field: 'pos_lon',
      headerName: 'Lon',
      sortable: true,
      valueGetter: ({row}) => row.pos_lon,
      renderCell: ({row}) => row.pos_lon,
    },
    {
      field: 'pos_lat',
      headerName: 'Lat',
      sortable: true,
      valueGetter: ({row}) => row.pos_lat,
      renderCell: ({row}) => row.pos_lat,
    },
    {
      field: 'beacon_data',
      headerName: 'Beacon Data',
      sortable: true,
      valueGetter: ({row}) => row.beacon_data,
      renderCell: ({row}) => row.beacon_data,
    },
    {
      field: 'commtrac_error_ellipse_major_radius',
      headerName: 'Error Ellipse Major Radius',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_error_ellipse_major_radius,
      renderCell: ({row}) => row.commtrac_error_ellipse_major_radius,
    },
    {
      field: 'commtrac_error_ellipse_minor_radius',
      headerName: 'Error Ellipse Minor Radius',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_error_ellipse_minor_radius,
      renderCell: ({row}) => row.commtrac_error_ellipse_minor_radius,
    },
    {
      field: 'commtrac_error_ellipse_orientation',
      headerName: 'Error Ellipse Orientation',
      sortable: true,
      valueGetter: ({row}) => row.commtrac_error_ellipse_orientation,
      renderCell: ({row}) => row.commtrac_error_ellipse_orientation,
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i: any) => selectedItems?.includes(`${i.id}`)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i: any) => `${i.id}`) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      fetchedData &&
      openedItem?.selectedIds &&
      openedItem.selectedIds.length !== selectedRows.length
    ) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: selectedRows.map((i: any) => `${i}`),
              },
            },
          },
        })
      );
    }
  }, [openedItem?.selectedIds, fetchedData]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <>
      <DashboardPanelTitleSlot>
        Belt Location History Report
      </DashboardPanelTitleSlot>

      <ResizableColumns
        left={
          <Map
            panel="ams_location_tracking_report"
            selectedMapLayers={config.mapLayers}
            selectedLevel={config.mapLevel}
            beltLocationHistoryData={selectedRows}
            minWidth={250}
            availableMapLayers={[
              'street',
              'mine',
              'nodes',
              'belt_location_history',
            ]}
            onSelectMapLayers={(v) => {
              onUpdate?.(
                update(config, {
                  mapLayers: {
                    $set: v,
                  },
                })
              );
            }}
            onSelectLevel={(v) => {
              onUpdate?.(
                update(config, {
                  mapLevel: {
                    $set: v,
                  },
                })
              );
            }}
          />
        }
      >
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {belts?.map((it) => (
                          <MenuItem
                            key={it.id}
                            onClick={() => {
                              const updateObject: any = {
                                activeId: {
                                  $set: it.id ?? undefined,
                                },
                                activeCommtracId: {
                                  $set: it.commtrac_external_id ?? undefined,
                                },
                              };
                              // if item is not already in openedItems add it
                              if (
                                !config.openedItems.find(
                                  (itm) => itm.id === it.id
                                )
                              ) {
                                updateObject.openedItems = {
                                  $set: [
                                    ...config.openedItems,
                                    {
                                      id: it.id ?? -1,
                                      commtrac_external_id:
                                        it.commtrac_external_id,
                                    },
                                  ],
                                };
                              }
                              onUpdate?.(update(value, updateObject));
                              popupState.close();
                            }}
                          >
                            {it.name || `Belt #${it?.id}`}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box px={2}>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={(_event, v) => {
                    if (v) {
                      onUpdate?.(
                        update(config, {
                          activeId: {
                            $set: v,
                          },
                        })
                      );
                    }
                  }}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? 'Undefined'}
                          onClose={() => {
                            onUpdate?.(
                              update(config, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? config.openedItems[0].id
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Assets Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  <ButtonGroup disabled={!openedItem}>
                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </ResizableColumns>
    </>
  );
};
