import PrintIcon from '@mui/icons-material/Print';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
  Alert,
  Backdrop,
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CircularProgress,
  Menu,
  MenuItem,
  Tab,
  Tabs,
} from '@mui/material';
import dayjs from 'dayjs';
import update from 'immutability-helper';
import PopupState, {bindMenu, bindTrigger} from 'material-ui-popup-state';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {useRefreshInterval} from '../../../hooks/refreshInterval';
import usePrevious from '../../../hooks/usePrevious';
import {AutoRefreshSelect} from '../../common/AutoRefreshSelect';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import TabLabel from '../../common/TabLabel';
import {DateRangeSelect} from '../../selectors/DateRangeSelect';
import {DashboardPanelTitleSlot} from '../DashboardPanelTitleSlot';

interface Props {
  value?: BeltHistoryReportData;
  onUpdate?: (value?: BeltHistoryReportData) => void;
}

const DEFAULT_SHOWN_FIELDS = [
  'network_id',
  'commtrac_external_id',
  'status',
  'date',
  'speed',
  'motor1',
  'motor2',
  'Power Fault',
  'Overload Motor 1 Fault',
  'Overload Motor 2 Fault',
  'Remote Switch Fault',
  'Fire Switch Fault',
  'Sequence Fault',
  'Dust Input Fault',
  'Belt Slip Fault',
  'Spill Switch Fault',
  'Misalignment Fault',
  'Take-Up Input Fault',
  'Motor 1 High Amp Fault',
  'Motor 2 High Amp Fault',
  'Motor 1 Low Amp Fault',
  'Motor 2 Low Amp Fault',
  'Fail to start Fault',
  'Belt Stop',
  'Belt Start',
  'Fire Alarm Light',
  'Dust Alarm Light',
  'Audible Alarm',
  'High Amps Motor 1',
  'High Amps Motor 2',
  'Auto Mode',
  'Horn Startup Active',
  'Command Received',
  'Misc Output 1 On',
  'Misc Output 2 On',
  'Misc Output 3 On',
  'Misc Output 4 On',
  'Slip Bypass',
  'Sequence In Bypass',
];

interface BeltHistoryReportDataTab {
  id: number;
  refreshInterval?: number | null;
  selectedIds?: string[];
  selectAll?: boolean;
  params?: {
    date_start?: string;
    date_end?: string;
    page?: number;
    limit?: number;
    order?: string;
    dir?: 'ASC' | 'DESC';
    status?: string;
  };
  commtrac_external_id?: number;
}

export interface BeltHistoryReportData {
  activeId?: number;
  activeCommtracId?: number;
  openedItems: BeltHistoryReportDataTab[];
}

export const getBeltHistoryReportData = () => ({
  activeId: undefined,
  activeCommtracId: undefined,
  openedItems: [],
});

export const getBeltHistoryReportDataTab = (
  id: number,
  commtrac_external_id?: number
): BeltHistoryReportDataTab => ({
  id,
  selectAll: true,
  params: {
    date_start: dayjs().format('YYYY-MM-DD'),
    date_end: dayjs().format('YYYY-MM-DD'),
  },
  commtrac_external_id,
});

export const BeltHistory = ({value, onUpdate}: Props) => {
  const isOpenAwayFromConnectView = useMemo(() => {
    return (
      location.pathname.includes('/panels/') ||
      !document.getElementById('connect-view-panel')
    );
  }, [location, value]);

  const config = useMemo(() => {
    const v = value ?? getBeltHistoryReportData();
    return {
      ...v,
    };
  }, [value]);

  const openedItemIndex = useMemo(
    () =>
      (config.activeId
        ? config.openedItems.findIndex((i) => i.id === config.activeId)
        : null) ?? config.openedItems.length - 1,
    [config.activeId, config.openedItems]
  );

  const openedItem = useMemo(
    () =>
      openedItemIndex !== -1
        ? config.openedItems[openedItemIndex]
          ? config.openedItems[openedItemIndex]
          : getBeltHistoryReportDataTab(config.activeId as number)
        : null,
    [openedItemIndex, config.openedItems]
  );

  const belts = useAppSelector(({assets}) => assets.belt_nodes);
  const tabNames = useMemo(
    () =>
      config.openedItems.map((o) => {
        const belt = belts.find((i: any) => i.id === o.id);
        return belt?.name || `Belt #${belt?.id}`;
      }),
    [config.openedItems, belts]
  );

  /*********/
  /* fetch */
  /*********/
  const [fetchedData, setFetchedData] = useState<any>();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const params = useMemo(
    () =>
      openedItem?.id
        ? {
            date_start:
              openedItem.params?.date_start ?? dayjs().format('YYYY-MM-DD'),
            date_end:
              openedItem.params?.date_end ?? dayjs().format('YYYY-MM-DD'),
            page: openedItem.params?.page ?? 0,
            limit: openedItem.params?.limit ?? 25,
            order: openedItem.params?.order ?? 'date',
            dir: openedItem.params?.dir ?? 'DESC',
            status: openedItem.params?.status ?? 'all',
          }
        : null,
    [openedItem?.id, openedItem?.params]
  );

  const fetchData = useCallback(
    async (params: any) => {
      try {
        setFetchedInProgress(true);
        try {
          const resp = await API.get(
            `${apiBaseUrl}/belt/${openedItem?.commtrac_external_id}/history`,
            {params}
          );
          setFetchedData(resp.data);
        } catch (error: any) {
          const messages = getMessagesFromApiError(error);
          setFetchedErrors(messages);
        }
        setFetchedInProgress(false);
      } catch (error) {
        console.log(error);
      }
    },
    [params, openedItem]
  );

  useEffect(() => {
    if (params) {
      fetchData(params);
    }
  }, [params]);

  useEffect(() => {
    if (!openedItem) {
      setFetchedData(undefined);
    }
  }, [openedItem]);

  /****************/
  /* auto refresh */
  /****************/

  const callFetchData = useCallback(() => {
    params && fetchData(params);
  }, [params]);

  useRefreshInterval(callFetchData, openedItem?.refreshInterval);

  /*********/
  /* grid  */
  /*********/
  const [shownFields, setShownFields] = useState(DEFAULT_SHOWN_FIELDS);
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = fetchedData?.items ?? [];
  const columns: DataGridColumn<any>[] = [
    {
      field: 'select',
      type: 'select',
      hideable: false,
      renderHeader: () => {
        return (
          <Checkbox
            color="primary"
            disabled={rows.length === 0}
            checked={selectedItems.length > 0 && selectedAll}
            indeterminate={selectedItems.length > 0 && !selectedAll}
            onChange={() => toggleSelectAllItems()}
          />
        );
      },
      renderCell: ({row}) => (
        <Checkbox
          color="primary"
          checked={selectedItems.includes(`${row.id}`)}
          onChange={() => toggleSelectItem(`${row.id}`)}
        />
      ),
    },
    {
      field: 'date',
      headerName: 'Timestamp',
      sortable: true,
      valueGetter: ({row}) => row.date,
      renderCell: ({row}) => {
        return row.date;
      },
    },
    {
      field: 'status',
      headerName: 'Belt Status',
      sortable: true,
      valueGetter: ({row}) => row.status,
    },
    {
      field: 'speed',
      headerName: 'Belt Speed',
      sortable: true,
      valueGetter: ({row}) => row.speed,
    },
    {
      field: 'motor1',
      headerName: 'Motor 1 Current',
      sortable: true,
      valueGetter: ({row}) => row.motor1,
    },
    {
      field: 'motor2',
      headerName: 'Motor 2 Current',
      sortable: true,
      valueGetter: ({row}) => row.motor2,
    },
    {
      field: 'Power Fault',
      headerName: 'Power Fault',
      sortable: true,
      valueGetter: ({row}) => row['Power Fault'],
    },
    {
      field: 'Overload Motor 1 Fault',
      headerName: 'Overload Motor 1 Fault',
      sortable: true,
      valueGetter: ({row}) => row['Overload Motor 1 Fault'],
    },
    {
      field: 'Overload Motor 2 Fault',
      headerName: 'Overload Motor 2 Fault',
      sortable: true,
      valueGetter: ({row}) => row['Overload Motor 2 Fault'],
    },
    {
      field: 'Remote Switch Fault',
      headerName: 'Remote Switch Fault',
      sortable: true,
      valueGetter: ({row}) => row['Remote Switch Fault'],
    },
    {
      field: 'Fire Switch Fault',
      headerName: 'Fire Switch Fault',
      sortable: true,
      valueGetter: ({row}) => row['Fire Switch Fault'],
    },
    {
      field: 'Sequence Fault',
      headerName: 'Sequence Fault',
      sortable: true,
      valueGetter: ({row}) => row['Sequence Fault'],
    },
    {
      field: 'Dust Input Fault',
      headerName: 'Dust Input Fault',
      sortable: true,
      valueGetter: ({row}) => row['Dust Input Fault'],
    },
    {
      field: 'Belt Slip Fault',
      headerName: 'Belt Slip Fault',
      sortable: true,
      valueGetter: ({row}) => row['Belt Slip Fault'],
    },
    {
      field: 'Spill Switch Fault',
      headerName: 'Spill Switch Fault',
      sortable: true,
      valueGetter: ({row}) => row['Spill Switch Fault'],
    },
    {
      field: 'Misalignment Fault',
      headerName: 'Misalignment Fault',
      sortable: true,
      valueGetter: ({row}) => row['Misalignment Fault'],
    },
    {
      field: 'Take-Up Input Fault',
      headerName: 'Take-Up Input Fault',
      sortable: true,
      valueGetter: ({row}) => row['Take-Up Input Fault'],
    },
    {
      field: 'Motor 1 High Amp Fault',
      headerName: 'Motor 1 High Amp Fault',
      sortable: true,
      valueGetter: ({row}) => row['Motor 1 High Amp Fault'],
    },
    {
      field: 'Motor 2 High Amp Fault',
      headerName: 'Motor 2 High Amp Fault',
      sortable: true,
      valueGetter: ({row}) => row['Motor 2 High Amp Fault'],
    },
    {
      field: 'Motor 1 Low Amp Fault',
      headerName: 'Motor 1 Low Amp Fault',
      sortable: true,
      valueGetter: ({row}) => row['Motor 1 Low Amp Fault'],
    },
    {
      field: 'Motor 2 Low Amp Fault',
      headerName: 'Motor 2 Low Amp Fault',
      sortable: true,
      valueGetter: ({row}) => row['Motor 2 Low Amp Fault'],
    },
    {
      field: 'Fail to start Fault',
      headerName: 'Fail to start Fault',
      sortable: true,
      valueGetter: ({row}) => row['Fail to start Fault'],
    },
    {
      field: 'Belt Stop',
      headerName: 'Belt Stop',
      sortable: true,
      valueGetter: ({row}) => row['Belt Stop'],
    },
    {
      field: 'Belt Start',
      headerName: 'Belt Start',
      sortable: true,
      valueGetter: ({row}) => row['Belt Start'],
    },
    {
      field: 'Fire Alarm Light',
      headerName: 'Fire Alarm Light',
      sortable: true,
      valueGetter: ({row}) => row['Fire Alarm Light'],
    },
    {
      field: 'Dust Alarm Light',
      headerName: 'Dust Alarm Light',
      sortable: true,
      valueGetter: ({row}) => row['Dust Alarm Light'],
    },
    {
      field: 'Audible Alarm',
      headerName: 'Audible Alarm',
      sortable: true,
      valueGetter: ({row}) => row['Audible Alarm'],
    },
    {
      field: 'High Amps Motor 1',
      headerName: 'High Amps Motor 1',
      sortable: true,
      valueGetter: ({row}) => row['High Amps Motor 1'],
    },
    {
      field: 'High Amps Motor 2',
      headerName: 'High Amps Motor 2',
      sortable: true,
      valueGetter: ({row}) => row['High Amps Motor 2'],
    },
    {
      field: 'Auto Mode',
      headerName: 'Auto Mode',
      sortable: true,
      valueGetter: ({row}) => row['Auto Mode'],
    },
    {
      field: 'Horn Startup Active',
      headerName: 'Horn Startup Active',
      sortable: true,
      valueGetter: ({row}) => row['Horn Startup Active'],
    },
    {
      field: 'Command Received',
      headerName: 'Command Received',
      sortable: true,
      valueGetter: ({row}) => row['Command Received'],
    },
    {
      field: 'Misc Output 1 On',
      headerName: 'Misc Output 1 On',
      sortable: true,
      valueGetter: ({row}) => row['Misc Output 1 On'],
    },
    {
      field: 'Misc Output 2 On',
      headerName: 'Misc Output 2 On',
      sortable: true,
      valueGetter: ({row}) => row['Misc Output 2 On'],
    },
    {
      field: 'Misc Output 3 On',
      headerName: 'Misc Output 3 On',
      sortable: true,
      valueGetter: ({row}) => row['Misc Output 3 On'],
    },
    {
      field: 'Misc Output 4 On',
      headerName: 'Misc Output 4 On',
      sortable: true,
      valueGetter: ({row}) => row['Misc Output 4 On'],
    },
    {
      field: 'Slip Bypass',
      headerName: 'Slip Bypass',
      sortable: true,
      valueGetter: ({row}) => row['Slip Bypass'],
    },
    {
      field: 'Sequence In Bypass',
      headerName: 'Sequence In Bypass',
      sortable: true,
      valueGetter: ({row}) => row['Sequence In Bypass'],
    },
  ];

  /*******************/
  /* multiple select */
  /*******************/
  const selectedItems = openedItem?.selectedIds ?? [];

  const selectedRows = useMemo(
    () => rows.filter((i: any) => selectedItems?.includes(`${i.id}`)),
    [rows, selectedItems]
  );

  const selectedAll = useMemo(
    () => rows.length === selectedRows.length,
    [rows, selectedRows]
  );

  const toggleSelectItem = (id: string) => {
    if (openedItem) {
      if (selectedItems?.includes(id)) {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: selectedItems.filter((i) => i !== id),
                  selectAll: false,
                },
              },
            },
          })
        );
      } else {
        onUpdate?.(
          update(config, {
            openedItems: {
              [openedItemIndex]: {
                $set: {
                  ...openedItem,
                  selectedIds: [...(selectedItems ?? []), id],
                  selectAll:
                    selectedItems.length + 1 === fetchedData?.items.length,
                },
              },
            },
          })
        );
      }
    }
  };

  const selectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: true,
              },
              selectedIds: {
                $set: rows?.map((i: any) => `${i.id}`) ?? [],
              },
            },
          },
        })
      );
    }
  };

  const unselectAll = () => {
    if (openedItem) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectAll: {
                $set: false,
              },
              selectedIds: {
                $set: [],
              },
            },
          },
        })
      );
    }
  };

  const toggleSelectAllItems = () => {
    if (selectedItems.length >= rows.length) {
      unselectAll();
    } else {
      selectAll();
    }
  };

  useEffect(() => {
    if (
      fetchedData &&
      openedItem?.selectedIds &&
      openedItem.selectedIds.length !== selectedRows.length
    ) {
      onUpdate?.(
        update(config, {
          openedItems: {
            [openedItemIndex]: {
              selectedIds: {
                $set: selectedRows.map((i: any) => `${i}`),
              },
            },
          },
        })
      );
    }
  }, [openedItem?.selectedIds, fetchedData]);

  const prevSelectedAll = usePrevious(selectedAll);

  useEffect(() => {
    if (prevSelectedAll && !selectedAll) {
      selectAll();
    }
  }, [rows]);

  return (
    <>
      <DashboardPanelTitleSlot>Belt History Report</DashboardPanelTitleSlot>

      <>
        <Box
          display="flex"
          flexDirection="column"
          height="100%"
          width="100%"
          overflow="hidden"
        >
          <Box
            display="flex"
            flexDirection="column"
            gap={3}
            py={1.5}
            bgcolor={(theme) =>
              theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF'
            }
          >
            {isOpenAwayFromConnectView && (
              <Box px={1.5}>
                <PopupState variant="popover" popupId="demo-popup-menu">
                  {(popupState) => (
                    <>
                      <Button variant="outlined" {...bindTrigger(popupState)}>
                        Select an Object
                      </Button>
                      <Menu {...bindMenu(popupState)}>
                        {belts?.map((it: any) => (
                          <MenuItem
                            key={it.id}
                            onClick={() => {
                              const updateObject: any = {
                                activeId: {
                                  $set: it.id ?? undefined,
                                },
                                activeCommtracId: {
                                  $set: it.commtrac_external_id ?? undefined,
                                },
                              };
                              // if item is not already in openedItems add it
                              if (
                                !config.openedItems.find(
                                  (itm) => itm.id === it.id
                                )
                              ) {
                                updateObject.openedItems = {
                                  $set: [
                                    ...config.openedItems,
                                    {
                                      id: it.id ?? -1,
                                      commtrac_external_id:
                                        it.commtrac_external_id,
                                    },
                                  ],
                                };
                              }
                              onUpdate?.(update(value, updateObject));
                              popupState.close();
                            }}
                          >
                            {it.name || `Belt #${it.id}`}
                          </MenuItem>
                        ))}
                      </Menu>
                    </>
                  )}
                </PopupState>
              </Box>
            )}
            {config.openedItems.length ? (
              <Box px={2}>
                <Tabs
                  value={openedItem?.id}
                  variant="scrollable"
                  onChange={(_event, v) => {
                    if (v) {
                      onUpdate?.(
                        update(config, {
                          activeId: {
                            $set: v,
                          },
                        })
                      );
                    }
                  }}
                >
                  {config.openedItems.map((i, idx) => (
                    <Tab
                      key={i.id}
                      value={i.id}
                      label={
                        <TabLabel
                          name={tabNames?.[idx] ?? ''}
                          onClose={() => {
                            onUpdate?.(
                              update(value, {
                                activeId: {
                                  $set:
                                    config.activeId === i.id
                                      ? config.openedItems[0].id
                                      : config.activeId,
                                },
                                openedItems: {
                                  $set:
                                    config.openedItems.filter(
                                      (o) => o.id && o.id !== i.id
                                    ) ?? [],
                                },
                              })
                            );
                          }}
                        />
                      }
                    />
                  ))}
                </Tabs>
              </Box>
            ) : (
              <Box minWidth={400} px={1.5}>
                <Alert severity="warning">No Assets Opened</Alert>
              </Box>
            )}

            <Box display="flex" flexDirection="column" px={1.5}>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                gap={2}
              >
                <Box display="flex" gap={1}>
                  <Box minWidth={400}>
                    <DateRangeSelect
                      value={
                        openedItem
                          ? [
                              dayjs(openedItem.params?.date_start).toDate(),
                              dayjs(openedItem.params?.date_end).toDate(),
                            ]
                          : undefined
                      }
                      size="small"
                      disabled={!openedItem}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    selectAll: true,
                                    params: {
                                      ...openedItem.params,
                                      date_start: v?.[0]
                                        ? dayjs(v?.[0]).format('YYYY-MM-DD')
                                        : undefined,
                                      date_end: v?.[0]
                                        ? dayjs(v?.[1]).format('YYYY-MM-DD')
                                        : undefined,
                                    },
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />
                  </Box>
                </Box>

                <Box display="flex">
                  {/* <ButtonGroup disabled={!openedItem}> */}
                  <ButtonGroup>
                    <Button
                      size="small"
                      onClick={() => params && fetchData(params)}
                    >
                      <RefreshIcon />
                    </Button>

                    <AutoRefreshSelect
                      value={openedItem?.refreshInterval ?? null}
                      onChange={(v) => {
                        if (openedItem) {
                          onUpdate?.(
                            update(config, {
                              openedItems: {
                                [openedItemIndex]: {
                                  $set: {
                                    ...openedItem,
                                    refreshInterval: v,
                                  },
                                },
                              },
                            })
                          );
                        }
                      }}
                    />

                    <Button
                      size="small"
                      onClick={() => dataGridRef.current?.printTable()}
                    >
                      <PrintIcon />
                    </Button>
                  </ButtonGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <Backdrop
            open={fetchedInProgress}
            sx={{position: 'absolute', zIndex: 1199}}
          >
            <CircularProgress color="inherit" />
          </Backdrop>

          {fetchedErrors.map((error, idx) => (
            <Alert
              key={idx}
              severity="error"
              onClose={() => params && fetchData(params)}
            >
              {error}
            </Alert>
          ))}

          <DataGrid
            ref={dataGridRef}
            rows={rows}
            columns={columns}
            loading={fetchedInProgress}
            shownFields={shownFields}
            pagination
            paginationMode="server"
            size="small"
            sortBy={
              params?.order
                ? {
                    field: params?.order,
                    dir: params?.dir === 'DESC' ? 'desc' : 'asc',
                  }
                : null
            }
            sortingMode="server"
            page={params?.page}
            pageSize={params?.limit}
            rowCount={fetchedData?.count}
            sxFooter={{
              bgcolor: (theme) =>
                theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
            }}
            onPageChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onPageSizeChange={(v) => {
              if (openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          selectAll: true,
                          params: {
                            ...openedItem.params,
                            page: 0,
                            limit: v,
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onSort={(v) => {
              if (v && openedItem) {
                onUpdate?.(
                  update(config, {
                    openedItems: {
                      [openedItemIndex]: {
                        $set: {
                          ...openedItem,
                          params: {
                            ...openedItem.params,
                            order: v.field,
                            dir: v.dir === 'desc' ? 'DESC' : 'ASC',
                          },
                        },
                      },
                    },
                  })
                );
              }
            }}
            onShownFieldsChange={setShownFields}
          />
        </Box>
      </>
    </>
  );
};
